import React, { Component } from 'react';
import DocuIcoWhite from './../../img/white/receipt_long_white_24dp.svg';
import DocuIcoBlack from './../../img/black/receipt_long_black_24dp.svg';
import InfoIco from './../../img/black/info_black_24dp.svg';
import CustomDate from './../../Components/CustomDate';
import BookingFormPaging from './BookingFormPaging';
import * as ajax from './../../ajax';
import FormComboPartner from './../../Components/FormComboPartner';
import openBlack from './../../img/black/operner.svg';
import openWhite from './../../img/white/operner.svg';
import './../../css/popup.css';
import FormComboWorkNumber from './../../Components/FormComboWorkNumber';
import FormComboMegjegyzes from './../../Components/FormComboMegjegyzes';

import attachEmailBlack from './../../img/black/attach_email_black_24dp.svg';
import attachEmailWhite from './../../img/white/attach_email_white_24dp.svg';

import MasterData from './../MasterData/MasterData';
import CompanyBackgroundInfoPanel from './../CompanyBackgroundInfo/CompanyBackgroundInfoPanel';
import PopUpWindow from './../../Components/PopUpWindow';
import AlertDialog from "./../../Components/AlertDialog";

class BookingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: {active: false, msg: ''},
            companyBackgroundInfoPanelVisible: false,
            focused: '',
            lastFilename: '',
            lastBlobUrl: '',

            black: true,
            partnerId: null,
            partnerNeve: '',
            arfolyam: null,
            partner: {id: null, value: ''},
            workNumber: {id: null, value: ''},
            partnerName: '',
            partnerAdoszam: '',
            partnerIRSZ: '',
            partnerCity: '',
            partnerUtca: '',
            referencePartner: React.createRef(),
            referenceBizonylatszam: React.createRef(),
            referenceKelt: React.createRef(),
            referenceTeljesites: React.createRef(),
            referenceFizetesiMod: React.createRef(),
            referenceFizetesiHatarido: React.createRef(),
            referenceAfaDatum: React.createRef(),
            referencePenznem: React.createRef(),
            referenceArfolyam: React.createRef(),
            referenceBrutto: React.createRef(),
            referenceBrutto1: React.createRef(),
            referenceBrutto2: React.createRef(),
            referenceBrutto3: React.createRef(),
            referenceNetto1: React.createRef(),
            referenceNetto2: React.createRef(),
            referenceNetto3: React.createRef(),
            referenceAfaMentes: React.createRef(),
            referenceMegjegyzes: React.createRef(),
            referenceFajlHelye: React.createRef(),
            referenceStatusz: React.createRef(),
            referenceForditottAfa: React.createRef(),
            referenceMessage: React.createRef(),
            referenceMessageAnswer: React.createRef(),
            referenceWorkNumberDetails: React.createRef(),
            referenceSelectedMonth: React.createRef(),
            diff1Error: false,
            diff2Error: false,
            diff3Error: false,
            workNumberDetails: '',
            data: {
                id: 0, //
                FILENEVE: "", // Fájl
                Status: 0, // Státusz
                EGYEDIAZ: "", // Iktatószám
                IKT_DATE: "", // Iktatás dátuma
                KONYDATE: "", // Könyvelés dátuma
                DOKUID: "", // Számlaszám
                GROSVAL: 0, // Bruttó összes érték
                CURRENC: 1, // Pénznem
                FIZMOD: 1, //  Fizetési mód
                DATUM: "", // Bizonylat dátuma
                TELJESIT: "", // Számviteli teljesítés
                HATIDO: "", // Fizetési Határidő
                AFATELJ: "", // ÁFA teljesítés dátuma
                SZALLITO: "", // Szállító neve
                SZALADO: "", // Szállító adószáma
                SZALIRSZ: "", // Szállító ir.szám
                SZALCITY: "", // Szállító helység
                SZALUTCA: "", // Szállító címe
                AFAMENT: 0, // ÁFA mentes?
                AFAKULC1: 0, // Áfa% 1
                TETELNE1: 0, // Nettó érték 1
                TETELBR1: 0, // Bruttó érték 1
                TETELAFA1: 0, // Áfa érték 1
                AFAKULC2: 0, // Áfa% 2
                TETELNE2: 0, // Nettó érték 2
                TETELBR2: 0, // Bruttó érték 2
                TETELAFA2: 0, // Áfa érték 2
                AFAKULC3: 0, // Áfa% 3
                TETELNE3: 0, // Nettó érték 3
                TETELBR3: 0, // Bruttó érték 3
                TETELAFA3: 0, // Áfa érték 2
                MEGJEGYZ: "", // Megjegyzés
                FORDAFA: 0, // Fordított áfa?
                exchangeRate: 0, // Árfolyam
                pdf: null,
                eredetiSzamla: '',
                message: '',
                messageAnswer: '',
                id_by_type: '',
                selectedMonth: 0

            },
            workNumberReference: React.createRef()
        }

        this.props.frameState.bookingFormReference = this;
        this.loadRecord(this.props.selectedRowId);

        document.onkeyup = function (e) {
            if (e.ctrlKey && e.code == 'Enter') {
                this.updateForm();
            }
        }.bind(this);

        this.months = ['Nincs Kiválasztva', 'Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
        
        this.isPopUpMode = typeof this.props.multiMonitorisPopUpMode!=='undefined' && this.props.multiMonitorisPopUpMode !==null?this.props.multiMonitorisPopUpMode:false;
        this.popupWindow = typeof this.props.multiMonitorPopupWindow !=='undefined'? this.props.multiMonitorPopupWindow:null;
        this.currentPopupKonyvelesTetelId = -1;
    }
    
    
    
    openOrUpdatePopup = function (konyvelesTetelId) {

        if (this.isPopUpMode === false) {
            return false;
        }
        if (konyvelesTetelId === this.currentPopupKonyvelesTetelId) {
            return false;
        }

        const popupUrl = ajax.getUrlBase() + "modules/Booking/popup.php?konyvelesTetelId=" + konyvelesTetelId;
        if (!this.popupWindow || this.popupWindow.closed) {
            // Új ablak nyitása, ha még nem nyitottuk meg, vagy ha az előző bezáródott
            this.popupWindow = window.open(popupUrl, "popupWindow");
           
        } else {
            // Ha már megnyitottuk, frissítjük a tartalmat
            this.popupWindow.location.href = popupUrl;
            //this.popupWindow.focus(); // Az ablak előtérbe hozása
        }
        this.currentPopupKonyvelesTetelId = konyvelesTetelId;
    }



    focusHandler = function () {
        if (this.state.focused != '') {
            if (this.state.focused == 'TETELBR1') {
                if (this.state.data.TETELBR1 == '') {
                    this.state.data.TETELBR1 = '0';
                    this.state.referenceBrutto1.current.value = 0;
                }
                this.state.referenceBrutto1.current.select();
                this.state.focused = '';
                return false;
            }
            if (this.state.focused == 'TETELNE1') {
                this.state.referenceNetto1.current.select();
                this.state.focused = '';
                return false;
            }
            if (this.state.focused == 'TETELBR2') {
                this.state.referenceBrutto2.current.select();
                this.state.focused = '';
                return false;
            }
            if (this.state.focused == 'TETELNE2') {
                this.state.referenceNetto2.current.select();
                this.state.focused = '';
                return false;
            }
            if (this.state.focused == 'TETELBR3') {
                this.state.referenceBrutto3.current.select();
                this.state.focused = '';
                return false;
            }
            if (this.state.focused == 'TETELNE3') {
                this.state.referenceNetto3.current.select();
                this.state.focused = '';
                return false;
            }
            if (this.state.focused == 'AFAMENT') {
                this.state.referenceAfaMentes.current.select();
                this.state.focused = '';
                return false;
            }
        }
        this.state.focused = '';
        return false;
    }

    componentDidUpdate(prevProps) {

        this.focusHandler();

        if (this.props.selectedInvoice == null) {
            return false;
        }

        if (prevProps.selectedInvoice == null && this.props.selectedInvoice == null) {
            return false;
        }

        if (prevProps.selectedInvoice == this.props.selectedInvoice) {
            return false;
        }
        this.state.workNumber = {id: null, value: ''};
        this.loadNav(this.props.selectedInvoice);
    }

    loadNav = function (selectedInvoice) {
        var invoiceNumber = selectedInvoice.invoiceNumber;
        var supplierTaxNumber = (typeof selectedInvoice.supplierTaxNumber != 'undefined' && selectedInvoice.supplierTaxNumber != null && selectedInvoice.supplierTaxNumber != '') ? selectedInvoice.supplierTaxNumber : '';

        try {
            const url = ajax.getUrlBase() + "NAV/QueryInvoiceData.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({invoiceNumber: invoiceNumber, invoiceDirection: this.props.invoiceDirection, supplierTaxNumber: supplierTaxNumber})
            }).then(response => {
                if (response.ok) {

                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.state.data["DOKUID"] = response.data.invoiceNumber;
                            this.state.data["DATUM"] = response.data.invoiceIssueDate;
                            this.state.data["TELJESIT"] = this.resolveDeliveryDate(response.data);
                            this.state.data["FIZMOD"] = this.resolvePaymentMethod(response.data);
                            this.state.data["HATIDO"] = this.resolvePaymentDate(response.data);
                            //this.state.data["AFATELJ"] = this.resolveinvoiceAccountingDeliveryDate(response.data); // invoiceAccountingDeliveryDate: Számviteli teljesítés dátuma. Időszak esetén az időszak utolsó napja?
                            this.state.data["AFATELJ"] = this.resolveDeliveryDate(response.data);
                            var currencyId = this.resolveCurrency(response.data);
                            this.state.data["CURRENC"] = currencyId;
                            if (currencyId == 1) {
                                this.state.data["exchangeRate"] = 1;
                            } else {
                                this.state.data["exchangeRate"] = this.resolveExchangeRate(response.data);
                            }
                            this.state.data["GROSVAL"] = this.resolveSummaryGrossData(response.data);

                            var afa1 = this.resolveAfa(response.data, 27);
                            var afa2 = this.resolveAfa(response.data, 18);
                            var afa3 = this.resolveAfa(response.data, 5);
                            var afa4 = this.resolveAfa(response.data, 0);



                            this.state.data["TETELNE1"] = (currencyId == 1) ? afa1.vatRateNetAmountHUF : afa1.vatRateNetAmount;
                            this.state.data["TETELBR1"] = (currencyId == 1) ? afa1.vatRateGrossAmountHUF : afa1.vatRateGrossAmount;
                            this.state.data["TETELAFA1"] = (currencyId == 1) ? afa1.vatRateVatAmountHUF : afa1.vatRateVatAmount;

                            this.state.data["TETELNE2"] = (currencyId == 1) ? afa2.vatRateNetAmountHUF : afa2.vatRateNetAmount;
                            this.state.data["TETELBR2"] = (currencyId == 1) ? afa2.vatRateGrossAmountHUF : afa2.vatRateGrossAmount;
                            this.state.data["TETELAFA2"] = (currencyId == 1) ? afa2.vatRateVatAmountHUF : afa2.vatRateVatAmount;

                            this.state.data["TETELNE3"] = (currencyId == 1) ? afa3.vatRateNetAmountHUF : afa3.vatRateNetAmount;
                            this.state.data["TETELBR3"] = (currencyId == 1) ? afa3.vatRateGrossAmountHUF : afa3.vatRateGrossAmount;
                            this.state.data["TETELAFA3"] = (currencyId == 1) ? afa3.vatRateVatAmountHUF : afa3.vatRateVatAmount;

                            this.state.data["AFAMENT"] = (currencyId == 1) ? afa4.vatRateGrossAmountHUF : afa4.vatRateGrossAmount;
                            this.state.data["FORDAFA"] = this.resolveFordAfa(response.data);

                            this.state.data["MEGJEGYZ"] = this.resolveComment(response.data);
                            if (this.props.invoiceDirection == "INBOUND") {
                                var partner = this.resolvePartnerSzallito(response.data);
                            }
                            if (this.props.invoiceDirection == "OUTBOUND") {
                                var partner = this.resolvePartnerVevo(response.data);
                            }

                            this.state.data["Status"] = 2; // Iktatáskor amikor a bizonylatszámot legördítem és a NAV OSA -ból választok akkor utána a státsuz azonnal álljon át ellenőrizve-re.
                            this.state.data["eredetiSzamla"] = this.resolveEredetiSzamla(response.data);
                            this.state.referenceBizonylatszam.current.focus();
                            this.setState({});


                            //this.state.partner['value'] = response.data[0].partner;
                            //this.state.data = this.handleNull(response.data);
                            //this.loadPartnerData(response.data[0].partner.id);

                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }
            ).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    removeWhiteSpaces = function (number) {

        if (typeof number == 'undefined') {
            return '';
        }
        if (number == null) {
            return '';
        }
        if (number == '') {
            return '';
        }

        number = number + '';

        return number.replace(/\s/g, '');
    }

    resolveDeliveryDate = function (responseData) {
        /** Ha nem szerepel a számlán a teljesítési dátumra vonatkozó explicit adat, akkor 
         az  Áfa törvény  alapján  a  teljesítés  dátuma  a  számla kelte (invoiceIssueDate),  tehát  az 
         invoiceDeliveryDate elemben ugyanazt a dátumot kell szerepeltetni, ami az invoiceIssueDate elemben 
         szerepel.  **/

        var result = responseData.invoiceIssueDate;
        try {

            result = responseData.invoices[0].invoiceHead.invoiceDetail.invoiceDeliveryDate;

        } catch (exception) {
            return result;
        }
        return result;
    }

    resolveinvoiceAccountingDeliveryDate = function (responseData) {
        var result = '';
        try {

            result = responseData.invoices[0].invoiceHead.invoiceDetail.invoiceAccountingDeliveryDate;

        } catch (exception) {
            return result;
        }

        return result;
    }

    resolvePaymentMethod = function (responseData) {
        //Technikailag szükséges : NEM
        //Áfa törvény szerint kötelező : NEM
        //Adatszolgáltatásban kötelező : NEM

        var ids = [];
        ids["TRANSFER"] = 2;
        ids["CASH"] = 1;
        ids["CARD"] = 3;
        ids["VOUCHER"] = 3;
        ids["OTHER"] = 4;

        var result = 4;
        try {
            var paymentMethodText = responseData.invoices[0].invoiceHead.invoiceDetail.paymentMethod;
            result = ids[paymentMethodText];
        } catch (exception) {
            return result;
        }
        return result;
    }

    resolvePaymentDate = function (responseData) {
        // Fizetési határidő
        //Technikailag szükséges : NEM
        //Áfa törvény szerint kötelező : NEM
        //Adatszolgáltatásban kötelező : NEM

        var result = '';
        try {
            result = responseData.invoices[0].invoiceHead.invoiceDetail.paymentDate;
        } catch (exception) {
            return result;
        }
        return result;
    }

    resolveCurrency = function (responseData) {

        /**
         *  
         NAV Online Számla rendszer 102. oldal 
         A  számláról  vagy  módosító  okiratról  történő  adatszolgáltatáskor  minden  esetben  meg  kell  adni  a 
         használt  pénznemet  (currencyCode)  és  az  átváltási  árfolyamot  (exchangeRate).  Forint  alapú  számla 
         esetén  1-et  kell  közölni.
         * 
         */

        var result = 1;

        var ids = [];
        ids["HUF"] = 1;
        ids["EUR"] = 2;
        ids["USD"] = 3;


        try {
            var paymentMethodText = responseData.invoices[0].invoiceHead.invoiceDetail.currencyCode;
            result = ids[paymentMethodText];
        } catch (exception) {
            return result;
        }
        return result;
    }

    resolveExchangeRate = function (responseData) {
        var result = '';
        try {
            result = responseData.invoices[0].invoiceHead.invoiceDetail.exchangeRate;
        } catch (exception) {
            return result;
        }
        return result;
    }

    hasGrossDataHUF = function (responseData) {
        if (typeof responseData == "undefined") {
            return false;
        }
        if (typeof responseData.invoices == "undefined") {
            return false;
        }
        if (typeof responseData.invoices[0] == "undefined") {
            return false;
        }
        if (typeof responseData.invoices[0].invoiceSummary == "undefined") {
            return false;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryGrossData == "undefined" ) {
            return false;
        }
        if (responseData.invoices[0].invoiceSummary.summaryGrossData === null ) {
            return false;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryGrossData.invoiceGrossAmountHUF == "undefined") {
            return false;
        }
        if (responseData.invoices[0].invoiceSummary.summaryGrossData.invoiceGrossAmountHUF === null) {
            return false;
        }
        return true;
    }

    hasGrossData = function (responseData) {
        if (typeof responseData == "undefined") {
            return false;
        }
        if (typeof responseData.invoices == "undefined") {
            return false;
        }
        if (typeof responseData.invoices[0] == "undefined") {
            return false;
        }
        if (typeof responseData.invoices[0].invoiceSummary == "undefined") {
            return false;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryGrossData == "undefined") {
            return false;
        }
        if ( responseData.invoices[0].invoiceSummary.summaryGrossData === null) {
            return false;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryGrossData.invoiceGrossAmountHUF == "undefined") {
            return false;
        }
        if (responseData.invoices[0].invoiceSummary.summaryGrossData.invoiceGrossAmountHUF === null) {
            return false;
        }
        return true;
    }

    getGrossFromSummaryByVatRateHUF = function (responseData) {

        var grossHUF = 0.0;

        if (typeof responseData == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0] == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0].invoiceSummary == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryNormal == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryNormal.summaryByVatRate == "undefined") {
            return 0.0;
        }

        var summaryByVatRate = responseData.invoices[0].invoiceSummary.summaryNormal.summaryByVatRate;
        if (summaryByVatRate == null) {
            return 0.0;
        }
        if (Array.isArray(summaryByVatRate) == false) {
            return 0.0;
        }
        if (summaryByVatRate.length == 0) {
            return 0.0;
        }

        for (var i = 0; i < summaryByVatRate.length; i++) {
            var summaryByVatRateItem = summaryByVatRate[i];
            if (typeof summaryByVatRateItem.vatRateNetData != "undefined" && summaryByVatRateItem.vatRateNetData != null) {
                if (typeof summaryByVatRateItem.vatRateNetData.vatRateNetAmountHUF != "undefined" && summaryByVatRateItem.vatRateNetData.vatRateNetAmountHUF != null) {
                    grossHUF += (summaryByVatRateItem.vatRateNetData.vatRateNetAmountHUF*1);
                }
            }


            if (typeof summaryByVatRateItem.vatRateNetData != "undefined" && summaryByVatRateItem.vatRateNetData != null) {
                if (typeof summaryByVatRateItem.vatRateVatData.vatRateVatAmountHUF != "undefined" && summaryByVatRateItem.vatRateVatData.vatRateVatAmountHUF != null) {
                    grossHUF += (summaryByVatRateItem.vatRateVatData.vatRateVatAmountHUF*1);
                }
            }
        }
        return grossHUF;
    }

    getGrossFromSummaryByVatRate = function (responseData) {

        var gross = 0.0;

        if (typeof responseData == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0] == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0].invoiceSummary == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryNormal == "undefined") {
            return 0.0;
        }
        if (typeof responseData.invoices[0].invoiceSummary.summaryNormal.summaryByVatRate == "undefined") {
            return 0.0;
        }

        var summaryByVatRate = responseData.invoices[0].invoiceSummary.summaryNormal.summaryByVatRate;
        if (summaryByVatRate == null) {
            return 0.0;
        }
        if (Array.isArray(summaryByVatRate) == false) {
            return 0.0;
        }
        if (summaryByVatRate.length == 0) {
            return 0.0;
        }

        for (var i = 0; i < summaryByVatRate.length; i++) {
            var summaryByVatRateItem = summaryByVatRate[i];
            if (typeof summaryByVatRateItem.vatRateNetData != "undefined" && summaryByVatRateItem.vatRateNetData != null) {
                if (typeof summaryByVatRateItem.vatRateNetData.vatRateNetAmount != "undefined" && summaryByVatRateItem.vatRateNetData.vatRateNetAmount != null) {
                    gross += (summaryByVatRateItem.vatRateNetData.vatRateNetAmount*1);
                }
            }


            if (typeof summaryByVatRateItem.vatRateNetData != "undefined" && summaryByVatRateItem.vatRateNetData != null) {
                if (typeof summaryByVatRateItem.vatRateVatData.vatRateVatAmount != "undefined" && summaryByVatRateItem.vatRateVatData.vatRateVatAmount != null) {
                    gross += (summaryByVatRateItem.vatRateVatData.vatRateVatAmount*1);
                }
            }
        }
        return gross.toFixed(2);
    }

    resolveSummaryGrossData = function (responseData) {
        var result = '';
        try {
            var currency = this.resolveCurrency(responseData);
            if (currency == 1) { // HUF
                var hasGrossHuff = this.hasGrossDataHUF(responseData);
                if (hasGrossHuff) { // HA VAN HUF ÖSSZESEN 
                    result = responseData.invoices[0].invoiceSummary.summaryGrossData.invoiceGrossAmountHUF;
                } else { // HA NINCS HUF ÖSSZESEN AKKOR A TÉTELEKBŐL KÉRJÜK LE
                    result = this.getGrossFromSummaryByVatRateHUF(responseData);
                }
            } else { // DEVIZA ESETÉN 
                var hasGross = this.hasGrossData(responseData);
                if (hasGross) { // HA VAN DEVIZA ÖSSZESEN
                    result = responseData.invoices[0].invoiceSummary.summaryGrossData.invoiceGrossAmount;
                } else { // HA NINCS DEVIZA ÖSSZESEN AKKOR A TÉTELEKBŐL KÉRJÜK LE
                    result = this.getGrossFromSummaryByVatRate(responseData);
                }
            }
        } catch (exception) {

            return result;
        }
        return result;
    }

    resolveAfa = function (responseData, afa) {

        var result = {
            vatRateNetAmountHUF: 0,
            vatRateVatAmountHUF: 0,
            vatRateGrossAmountHUF: 0,

            vatRateNetAmount: 0, // Deviza
            vatRateVatAmount: 0, // Deviza
            vatRateGrossAmount: 0 // Deviza
        };

        try {
            var vatSummeries = responseData.invoices[0].invoiceSummary.summaryNormal.summaryByVatRate;
            for (var i = 0; i < vatSummeries.length; i++) {
                var vatSum = vatSummeries[i];
                var vatPercentage = vatSum.vatRate.vatPercentage;
                if (vatPercentage == "") {

                }
                vatPercentage = (vatPercentage == "") ? 0 : vatPercentage;
                if (vatPercentage * 100 == afa) {
                    result.vatRateNetAmountHUF += vatSum.vatRateNetData.vatRateNetAmountHUF * 1.0;
                    result.vatRateVatAmountHUF += vatSum.vatRateVatData.vatRateVatAmountHUF * 1.0;
                    result.vatRateGrossAmountHUF = result.vatRateNetAmountHUF + result.vatRateVatAmountHUF;

                    result.vatRateNetAmount += vatSum.vatRateNetData.vatRateNetAmount * 1.0;
                    result.vatRateVatAmount += vatSum.vatRateVatData.vatRateVatAmount * 1.0;
                    result.vatRateGrossAmount = result.vatRateNetAmount + result.vatRateVatAmount;
                }
            }

        } catch (exception) {
            return result;
        }

        return result;
    }

    resolveFordAfa = function (responseData, afa) {
        try {
            var vatSummeries = responseData.invoices[0].invoiceSummary.summaryNormal.summaryByVatRate;
            for (var i = 0; i < vatSummeries.length; i++) {
                var vatSum = vatSummeries[i];
                var vatDomesticReverseCharge = vatSum.vatRate.vatDomesticReverseCharge;
                if (typeof vatDomesticReverseCharge != 'undefined' && vatDomesticReverseCharge != null && vatDomesticReverseCharge != '') {

                    if (vatDomesticReverseCharge == 'TRUE') {
                        return true;
                    }

                    if (vatDomesticReverseCharge == '1') {
                        return true;
                    }


                }
            }

        } catch (exception) {
            return false;
        }

        return false;
    }

    resolveComment = function (responseData) {
        var result = "";
        try {
            result = responseData.invoices[0].invoiceHead.invoiceDetail.additionalInvoiceData[0].dataValue[0];
        } catch (exception) {
            return result;
        }
        return result;
    }

    resolveEredetiSzamla = function (responseData) {
        var result = "";
        try {
            result = responseData.invoices[0].invoiceReference.originalInvoiceNumber;
        } catch (exception) {
            return result;
        }
        return result;
    }

    resolvePartnerSzallito = function (responseData) {
        try {
            var supplierName = responseData.invoices[0].invoiceHead.supplierInfo.supplierName;

            var taxNumber = responseData.invoices[0].invoiceHead.supplierInfo.supplierTaxNumber.taxpayerId;

            var request = {

                taxNumber: (typeof taxNumber == "undefined") ? "" : taxNumber,
                supplierName: (typeof supplierName == "undefined") ? "" : supplierName,

                countryCode: "",
                region: "",
                postalCode: "",

                city: "",
                streetName: "",
                publicPlaceCategory: "",
                number: "",
                building: "",
                staircase: "",
                floor: "",
                door: "",
                lotNumber: "",
                additionalAddressDetail: ""
            }


            var address = responseData.invoices[0].invoiceHead.supplierInfo.supplierAddress.address;
            if (address != null) {

                if (typeof address.countryCode != "undefined") {
                    request.countryCode = address.countryCode;
                }
                if (typeof address.region != "undefined") {
                    request.region = address.region;
                }
                if (typeof address.postalCode != "undefined") {
                    request.postalCode = address.postalCode;
                }
                if (typeof address.city != "undefined") {
                    request.city = address.city;
                }
                if (typeof address.streetName != "undefined") {
                    request.streetName = address.streetName;
                }
                if (typeof address.publicPlaceCategory != "undefined") {
                    request.publicPlaceCategory = address.publicPlaceCategory;
                }
                if (typeof address.number != "undefined") {
                    request.number = address.number;
                }
                if (typeof address.building != "undefined") {
                    request.building = address.building;
                }
                if (typeof address.staircase != "undefined") {
                    request.staircase = address.staircase;
                }
                if (typeof address.floor != "undefined") {
                    request.floor = address.floor;
                }
                if (typeof address.door != "undefined") {
                    request.door = address.door;
                }
                if (typeof address.lotNumber != "undefined") {
                    request.lotNumber = address.lotNumber;
                }
                if (typeof address.additionalAddressDetail != "undefined") {
                    request.additionalAddressDetail = address.additionalAddressDetail;
                }
            }


            const url = ajax.getUrlBase() + "NAV/resolvePartner.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(request)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.loadPartnerData(response.data.partnerId);
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });

        } catch (exception) {
            console.log(exception);
        }
    }

    getPartnerVevoAdoszam = function (customerInfo) {
        var communityVatNumber = "";

        if (customerInfo == null) {
            return '';
        }
        if (customerInfo.customerVatData == null) {
            return '';
        }

        if (typeof customerInfo.customerVatData.communityVatNumber != 'undefined' && customerInfo.customerVatData.communityVatNumber != null && customerInfo.customerVatData.communityVatNumber.trim() != '') {
            communityVatNumber = customerInfo.customerVatData.communityVatNumber;
        }

        if (customerInfo.customerVatData.customerTaxNumber == null) {
            return communityVatNumber;
        }
        if (customerInfo.customerVatData.customerTaxNumber.customerTaxNumber == null) {
            return communityVatNumber;
        }
        if (customerInfo.customerVatData.customerTaxNumber.customerTaxNumber.taxpayerId != null) {
            return customerInfo.customerVatData.customerTaxNumber.customerTaxNumber.taxpayerId;
        }
        return communityVatNumber;
    }

    resolvePartnerVevo = function (responseData) {

        try {
            var supplierName = responseData.invoices[0].invoiceHead.customerInfo.customerName;

            var taxNumber = this.getPartnerVevoAdoszam(responseData.invoices[0].invoiceHead.customerInfo);



            var request = {

                taxNumber: (typeof taxNumber == "undefined") ? "" : taxNumber,
                supplierName: (typeof supplierName == "undefined") ? "" : supplierName,

                countryCode: "",
                region: "",
                postalCode: "",

                city: "",
                streetName: "",
                publicPlaceCategory: "",
                number: "",
                building: "",
                staircase: "",
                floor: "",
                door: "",
                lotNumber: "",
                additionalAddressDetail: ""
            }


            var address = responseData.invoices[0].invoiceHead.customerInfo.customerAddress.address;
            if (address != null) {

                if (typeof address.countryCode != "undefined") {
                    request.countryCode = address.countryCode;
                }
                if (typeof address.region != "undefined") {
                    request.region = address.region;
                }
                if (typeof address.postalCode != "undefined") {
                    request.postalCode = address.postalCode;
                }
                if (typeof address.city != "undefined") {
                    request.city = address.city;
                }
                if (typeof address.streetName != "undefined") {
                    request.streetName = address.streetName;
                }
                if (typeof address.publicPlaceCategory != "undefined") {
                    request.publicPlaceCategory = address.publicPlaceCategory;
                }
                if (typeof address.number != "undefined") {
                    request.number = address.number;
                }
                if (typeof address.building != "undefined") {
                    request.building = address.building;
                }
                if (typeof address.staircase != "undefined") {
                    request.staircase = address.staircase;
                }
                if (typeof address.floor != "undefined") {
                    request.floor = address.floor;
                }
                if (typeof address.door != "undefined") {
                    request.door = address.door;
                }
                if (typeof address.lotNumber != "undefined") {
                    request.lotNumber = address.lotNumber;
                }
                if (typeof address.additionalAddressDetail != "undefined") {
                    request.additionalAddressDetail = address.additionalAddressDetail;
                }
            }


            const url = ajax.getUrlBase() + "NAV/resolvePartner.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(request)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.loadPartnerData(response.data.partnerId);
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });

        } catch (exception) {
            console.log(exception);
        }
    }

    handleNull = function (data) {
        if (data.partnerId == null) {
            this.state.partnerId = null;
            this.state.partnerNeve = '';
            this.state.partner = {id: null, value: ''};
        }
        if (typeof data.workNumber == 'undefined' || data.workNumber == null) {
            this.state.data['workNumber'] = {id: null, value: ''};
        }
        data.DOKUID = (data.DOKUID != null) ? data.DOKUID : '';
        data.FIZMOD = (data.FIZMOD != null) ? data.FIZMOD : 0;
        data.CURRENC = (data.CURRENC != null) ? data.CURRENC : 0;
        data.exchangeRate = (data.exchangeRate != null) ? data.exchangeRate : '';
        data.GROSVAL = (data.GROSVAL != null) ? data.GROSVAL : 0.0;

        data.IKT_DATE = (data.IKT_DATE != null) ? data.IKT_DATE : null;
        data.KONYDATE = (data.KONYDATE != null) ? data.KONYDATE : null;
        data.DATUM = (data.DATUM != null) ? data.DATUM : null;
        data.TELJESIT = (data.TELJESIT != null) ? data.TELJESIT : null;
        data.HATIDO = (data.HATIDO != null) ? data.HATIDO : null;
        data.AFATELJ = (data.AFATELJ != null) ? data.AFATELJ : null;

        data.AFAKULC1 = (data.AFAKULC1 != null) ? data.AFAKULC1 : 0;
        data.AFAKULC2 = (data.AFAKULC2 != null) ? data.AFAKULC2 : 1;
        data.AFAKULC3 = (data.AFAKULC3 != null) ? data.AFAKULC3 : 2;

        data.TETELNE1 = (data.TETELNE1 != null) ? data.TETELNE1 : 0.0;
        data.TETELNE2 = (data.TETELNE2 != null) ? data.TETELNE2 : 0.0;
        data.TETELNE3 = (data.TETELNE3 != null) ? data.TETELNE3 : 0.0;

        data.TETELBR1 = (data.TETELBR1 != null) ? data.TETELBR1 : 0.0;
        data.TETELBR2 = (data.TETELBR2 != null) ? data.TETELBR2 : 0.0;
        data.TETELBR3 = (data.TETELBR3 != null) ? data.TETELBR3 : 0.0;

        data.TETELAFA1 = (data.TETELAFA1 != null) ? data.TETELAFA1 : 0.0;
        data.TETELAFA2 = (data.TETELAFA2 != null) ? data.TETELAFA2 : 0.0;
        data.TETELAFA3 = (data.TETELAFA3 != null) ? data.TETELAFA3 : 0.0;

        data.AFAMENT = (data.AFAMENT != null) ? data.AFAMENT : 0.0;

        data.MEGJEGYZ = (data.MEGJEGYZ != null) ? data.MEGJEGYZ : '';

        data.Status = (data.Status != null) ? data.Status : 0;
        data.message = (data.message != null) ? data.message : '';
        data.messageAnswer = (data.messageAnswer != null) ? data.messageAnswer : '';

        return data;
    }

    loadRecord = function (recordId) {
        this.loadRecordCallback(recordId);
    }

    loadRecord = function (recordId, bookingFormReference) {

        if (typeof bookingFormReference != 'undefined') {
            var callback = this.loadRecord(recordId);
            bookingFormReference.updateForm(callback);
        } else {
            this.loadRecordCallback(recordId);
        }
    }

    loadRecordCallback = function (recordId) {

        try {
            const url = ajax.getUrlBase() + "modules/Booking/selectRecord.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: recordId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {

                            if (typeof response.data != 'undefined' && response.data != null) {


                                this.state.partner['value'] = response.data[0].partner;
                                this.state.workNumber['value'] = response.data[0].workNumber;
                                this.state.workNumberDetails = response.data.workNumberDetails;

                                this.state.data = this.handleNull(response.data);
                                this.loadPartnerData(response.data[0].partner.id);
                                this.openOrUpdatePopup(recordId);
                                //  this.setState({});
                            }
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    getWorkNumberValuePack = function () {
        if (typeof this.state.workNumberReference.current.state.selectedText == 'undefined') {
            return null;
        }
        if (this.state.workNumberReference.current.state.selectedText == null) {
            return null;
        }
        var workNumberTextFieldValue = this.state.workNumberReference.current.state.selectedText;
        var workNumberId = this.state.workNumber.id;
        var workNumberValue = this.state.workNumber.value;
        return  {id: workNumberId, value: workNumberValue, workNumberTextFieldValue: workNumberTextFieldValue};
    }

    updateForm = function (callback) {

        var workNumberValuePack = this.getWorkNumberValuePack();

        if (this.state.partner.id == null && this.state.partner.value != null) {
            this.state.data.partnerId = this.state.partner.value;
            this.state.data.SZALLITO = this.state.partnerName;
            this.state.data.SZALADO = this.state.partnerAdoszam;
            this.state.data.SZALIRSZ = this.state.partnerIRSZ;
            this.state.data.SZALCITY = this.state.partnerCity;
            this.state.data.SZALUTCA = this.state.partnerUtca;
        }

        if (this.state.data.FIZMOD == null) {
            this.state.data.FIZMOD = 1;
        }

        var formData = this.state.data;
        formData['workNumberValuePack'] = workNumberValuePack;
        formData["filesArray"] = (this.state.filesArray);

        try {
            const url = ajax.getUrlBase() + "modules/Booking/updateForm.php";
            var reload = this.reload.bind(this);
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({invoiceDirection: this.props.invoiceDirection, data: formData})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.props.parentState['lastInsertId'] = -1;
                            if (typeof callback != "undefined" || (!callback instanceof Function)) {
                                this.props.closeBookingForm();
                            } else {
                                callback();
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    componentWillMount() {
        /**
         if (this.state.black) {
         require('./../../css/popupWhite.css');
         } else {
         require('./../../css/popupBlack.css');
         }
         */
    }

    closeBookingForm = function () {
        this.props.closeBookingForm();
    }

    reload = function (key, value) {
        this.state.data[key] = value;
        this.setState({});
    }

    setEGYEDIAZ = function (e) {
        this.state.data.EGYEDIAZ = e.target.value;
        this.setState({});
    }

    setDOKUID = function (e) {
        this.state.data.DOKUID = e.target.value;
        this.setState({});
    }

    setPartnerNeve = function (e) {
        this.setState({partnerNeve: e.target.value});
    }

    getFizmodokOptions = function (fizmodok) {

        var items = [];
        for (var i = 0; i < fizmodok.length; i++) {
            var fizmod = fizmodok[i];
            items.push(<option key={"fizmod_" + fizmod.id} value={fizmod.id} >{fizmod.label}</option>);
        }
        return items;
    }

    setFizmod = function (e) {
        this.state.data.FIZMOD = e.target.value;
        this.setState({});
    }

    getPenznemekOptions = function (penznemek) {

        var items = [];
        for (var i = 0; i < penznemek.length; i++) {
            var fizmod = penznemek[i];
            items.push(<option key={"penzemek_" + fizmod.id} value={fizmod.id} >{fizmod.label}</option>);
        }
        return items;
    }

    setCURRENC = function (e) {
        this.state.data.CURRENC = e.target.value;
        this.setState({});
    }

    setExchangeRate = function (e) {
        this.state.data.exchangeRate = e.target.value;
        this.setState({});
    }

    setGROSVAL = function (e) {

        this.state.data.GROSVAL = this.removeWhiteSpaces(e.target.value);
        this.setState({});

    }

    focusLostGROSVAL = function (e) {

        var GROSVAL = (this.state.data.GROSVAL == '') ? 0 : this.state.data.GROSVAL;
        var TETELBR3 = (this.state.data.TETELBR3 == '') ? 0 : this.state.data.TETELBR3;
        var TETELBR2 = (this.state.data.TETELBR2 == '') ? 0 : this.state.data.TETELBR2;
        var AFAMENT = (this.state.data.AFAMENT == '') ? 0 : this.state.data.AFAMENT;

        GROSVAL = (typeof this.state.data.GROSVAL == 'string') ? this.state.data.GROSVAL * 1.0 : this.state.data.GROSVAL;
        TETELBR3 = (typeof this.state.data.TETELBR3 == 'string') ? this.state.data.TETELBR3 * 1.0 : this.state.data.TETELBR3;
        TETELBR2 = (typeof this.state.data.TETELBR2 == 'string') ? this.state.data.TETELBR2 * 1.0 : this.state.data.TETELBR2;
        AFAMENT = (typeof this.state.data.AFAMENT == 'string') ? this.state.data.AFAMENT * 1.0 : this.state.data.AFAMENT;

        var TETELBR1 = GROSVAL - (TETELBR2 + TETELBR3 + AFAMENT);
        var tetelBrutto1 = {target: {value: TETELBR1}};
        this.focusLostTETELBR1(tetelBrutto1);
        //this.setTETELBR1({target: {value: this.state.data.GROSVAL}})
    }

    setFORDAFA = function (e) {
        this.state.data.FORDAFA = (e.target.checked) ? 1 : 0;
        this.setState({});
    }

    setAFAMENT = function (e) {
        this.state.data.AFAMENT = this.removeWhiteSpaces(e.target.value);
        this.setState({});
    }
    setMessage = function (e) {
        this.state.data.message = e.target.value;
        this.setState({});
    }
    setMessageAnswer = function (e) {
        this.state.data.messageAnswer = e.target.value;
        this.setState({});
    }

    resolveAfaValueById = function (id) {
        switch (id) {
            case 1:
                return 27;
                break;
            case 2 :
                return 18;
                break;
            case 3 :
                return 5;
                break;
        }
    }

    numberSolver = function () {}

    zeroCorrection = function (val) {

        if (typeof val != 'undefined' && val != null && typeof val == 'string') {
            if (val.charAt(0) == '0' && val.charAt(1) != '.' && val.length == 2) {
                return val.substring(1);
            }

            if (val.charAt(0) == '-' && val.charAt(1) == '0' && val.charAt(2) != '.' && val.length == 3) {

                return  "-" + val.substring(2);
            }
        }
        return val;
    }

    devizaRound = function (num) {
        num = parseFloat(num);
        var rounded = Math.round((num + Number.EPSILON) * 100) / 100;
        return rounded;
    }

    rounded = function (brutto, netto, afakulcs) {
        if (this.state.data.CURRENC > 1) {
            var newBrutto = this.devizaRound(brutto);
            var newNetto = this.devizaRound(netto);
            var newAfa = this.devizaRound(newBrutto - newNetto);
            return   {brutto: newBrutto, netto: newNetto, afa: newAfa}
        }

        if (typeof brutto == 'undefined' || typeof netto == 'undefined') {
            return 0;
        }
        if (brutto == null || netto == null) {
            return 0;
        }

        var afa = brutto * 1.0 - netto * 1.0;
        var newAfa = 0.0;
        if (afa % 1 != 0.0) {
            newAfa = Math.round(afa);
        } else {
            newAfa = afa;
        }

        var newNetto = Math.round((afa / afakulcs) * 100);
        var newBrutto = netto + afa;
        return  {brutto: newBrutto, netto: newNetto, afa: newAfa}
    }

    diffChecker(brutto, netto, afakulcs) {
        var realBrutto = (netto * 1.0) + ((netto * 1.0 / 100) * (afakulcs * 1.0));
        var diff = brutto - realBrutto;
        var error = (Math.abs(diff) > 1) ? true : false;
        return {diff: diff, error: error}
    }

    setTETELNE1 = function (e) {

        if (e.target.value == '-') {
            this.state.data.TETELNE1 = '-';
            this.setState({});
            return false;
        }
        var value = this.removeWhiteSpaces(e.target.value);
        value = (value != '') ? value : 0.0;
        value = this.zeroCorrection(value);

        this.state.data.TETELNE1 = value;
        var afaValue = this.state.data.TETELBR1 - this.state.data.TETELNE1;
        afaValue = (this.state.data.CURRENC > 1) ? this.devizaRound(afaValue) : afaValue;
        this.state.data.TETELAFA1 = afaValue;
        this.setState({});
    }

    checkTETELNE1 = function (e) {

        if (e.target.value == '-') {
            this.state.data.TETELNE1 = '-';
            this.setState({});
            return false;
        }

        var afaSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC1);
        var value = (e.target.value != '') ? e.target.value * 1 : 0.0;
        this.state.data.TETELNE1 = value;
        var diff = this.diffChecker(this.state.data.TETELBR1, this.state.data.TETELNE1, afaSzazalek);
        var rounded = this.state.data.GROSVAL - this.state.data.TETELBR1 - this.state.data.TETELBR3 - this.state.data.AFAMENT;
        rounded = (this.state.data.CURRENC > 1) ? this.devizaRound(rounded) : rounded;
        this.setTETELBR2({target: {value: rounded}});
        this.setState({diff1Error: diff.error});
    }

    passFocusTetelAfa1 = function (e) {
        if (this.state.diff1Error == false) {
            this.setState({focused: 'TETELBR2'});


        }
    }
    passFocusTetelAfa2 = function (e) {
        if (this.state.diff2Error == false) {
            this.setState({focused: 'TETELBR3'});
        }
    }
    passFocusTetelAfa3 = function (e) {

    }

    setTETELNE2 = function (e) {

        if (e.target.value == '-') {
            this.state.data.TETELNE2 = '-';
            this.setState({});
            return false;
        }

        var value = this.removeWhiteSpaces(e.target.value);
        value = (value != '') ? value : 0.0;
        value = this.zeroCorrection(value);

        this.state.data.TETELNE2 = value;
        this.state.data.TETELAFA2 = this.state.data.TETELBR2 - this.state.data.TETELNE2;
        this.setState({});
    }

    checkTETELNE2 = function (e) {
        var afaSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC2);
        var value = (e.target.value != '') ? e.target.value * 1 : 0.0;
        this.state.data.TETELNE2 = value;
        var diff = this.diffChecker(this.state.data.TETELBR2, this.state.data.TETELNE2, afaSzazalek);

        var rounded = this.state.data.GROSVAL - this.state.data.TETELBR1 - this.state.data.TETELBR2 - this.state.data.AFAMENT;
        rounded = (this.state.data.CURRENC > 1) ? this.devizaRound(rounded) : rounded;

        this.setTETELBR3({target: {value: rounded}});
        this.setState({diff2Error: diff.error});
    }

    setTETELNE3 = function (e) {
        if (e.target.value == '-') {
            this.state.data.TETELNE3 = '-';
            this.setState({});
            return false;
        }

        var value = this.removeWhiteSpaces(e.target.value);
        value = (value != '') ? value : 0.0;
        value = this.zeroCorrection(value);

        this.state.data.TETELNE3 = value;
        this.state.data.TETELAFA3 = this.state.data.TETELBR3 - this.state.data.TETELNE3;
        this.setState({});
    }

    checkTETELNE3 = function (e) {

        var afaSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC3);
        this.state.data.TETELNE3 = e.target.value;
        var diff = this.diffChecker(this.state.data.TETELBR3, this.state.data.TETELNE3, afaSzazalek);
        var afament = (this.state.data.GROSVAL * 1.0) - ((this.state.data.TETELBR1 + this.state.data.TETELBR2 + this.state.data.TETELBR3) * 1.0);

        afament = (this.state.data.CURRENC > 1) ? this.devizaRound(afament) : afament;

        this.setAFAMENT({target: {value: afament}});
        this.setState({diff3Error: diff.error, focused: 'AFAMENT'});
    }

    setTETELBR1 = function (e) {
        var value = this.removeWhiteSpaces(e.target.value);
        value = this.zeroCorrection(value);
        this.state.data.TETELBR1 = value;
        this.setState({});
    }

    focusLostTETELBR1 = function (e) {

        var value = e.target.value;

        this.state.data.TETELBR1 = value;
        var afaErtekSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC1);
        this.state.data.TETELNE1 = (value / (100 + afaErtekSzazalek)) * 100;

        if (value == '0') {
            var tetelbruttoVal = this.state.data.GROSVAL - this.state.data.TETELBR1 - this.state.data.TETELBR3 - this.state.data.AFAMENT;
            tetelbruttoVal = (this.state.data.CURRENC > 1) ? this.devizaRound(tetelbruttoVal) : tetelbruttoVal;
            this.setTETELBR2({target: {value: tetelbruttoVal}});
            if (e.type == 'blur') {
                this.setState({focused: 'TETELBR2'});
            }

        }

        var rounded = this.rounded(this.state.data.TETELBR1, this.state.data.TETELNE1, afaErtekSzazalek);
        this.state.data.TETELBR1 = rounded.brutto;
        this.state.data.TETELNE1 = rounded.netto;
        this.state.data.TETELAFA1 = rounded.afa;
        this.setState({});
    }

    setTETELBR2 = function (e) {
        var value = this.removeWhiteSpaces(e.target.value);
        value = this.zeroCorrection(value);
        this.state.data.TETELBR2 = value;
        this.setState({});
    }

    focusLostTETELBR2 = function (e) {
        var value = (e.target.value != '') ? e.target.value : 0.0;

        var afaErtekSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC2);
        this.state.data.TETELNE2 = (value / (100 + afaErtekSzazalek)) * 100;

        this.state.data.TETELBR2 = value;
        if (value == '0') {

            var tetelbruttoVal = this.state.data.GROSVAL - this.state.data.TETELBR1 - this.state.data.TETELBR2 - this.state.data.AFAMENT;
            tetelbruttoVal = (this.state.data.CURRENC > 1) ? this.devizaRound(tetelbruttoVal) : tetelbruttoVal;

            this.setTETELBR3({target: {value: tetelbruttoVal}});
            this.setState({focused: 'TETELBR3'});
        }

        var rounded = this.rounded(this.state.data.TETELBR2, this.state.data.TETELNE2, afaErtekSzazalek);
        this.state.data.TETELBR2 = rounded.brutto;
        this.state.data.TETELNE2 = rounded.netto;
        this.state.data.TETELAFA2 = rounded.afa;
        this.setState({});
    }

    setTETELBR3 = function (e) {
        var value = this.removeWhiteSpaces(e.target.value);
        value = this.zeroCorrection(value);
        this.state.data.TETELBR3 = value;
        this.setState({});
    }

    focusLostTETELBR3 = function (e) {
        var value = (e.target.value != '') ? e.target.value : 0.0;
        var afaErtekSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC3);
        this.state.data.TETELNE3 = (value / (100 + afaErtekSzazalek)) * 100;
        this.state.data.TETELBR3 = value;
        var rounded = this.rounded(this.state.data.TETELBR3, this.state.data.TETELNE3, afaErtekSzazalek);
        this.state.data.TETELBR3 = rounded.brutto;
        this.state.data.TETELNE3 = rounded.netto;
        this.state.data.TETELAFA3 = rounded.afa;
        if (value == 0) {
            var afament = (this.state.data.GROSVAL * 1.0) - this.state.data.TETELBR1 - this.state.data.TETELBR2 - this.state.data.TETELBR3;
            afament = (this.state.data.CURRENC > 1) ? this.devizaRound(afament) : afament;
            this.setAFAMENT({target: {value: afament}});
            this.setState({focused: 'AFAMENT'});
        }
        this.setState({});
    }

    correctionDialog1 = function () {

        var newBrutto = function () {
            var afaSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC1);
            var netto = this.state.data.TETELNE1 * 1;
            this.state.data.TETELBR1 = this.calcBrutto(netto, afaSzazalek);
            this.state.data.TETELAFA1 = this.state.data.TETELBR1 - netto;
            this.setState({diff1Error: false, focused: 'TETELNE1'});
        }

        var ignore = function () {
            this.setState({diff1Error: false});
            this.setTETELBR2({target: {value: this.state.data.GROSVAL - this.state.data.TETELBR1}});
            this.setState({focused: 'TETELBR2'});
        }

        return <div  style={{position: 'absolute', top: '225px', left: '320px', backgroundColor: "#ffffff", zIndex: 2000, boxShadow: '0px 0px 3px #00000077', padding: '3px', display: 'flex'}}>
        
            <div style={{textAlign: 'center', color: '#aa0000'}} >Hibás nettó!</div>
            <button onClick={newBrutto.bind(this)} style={{marginLeft: '5px'}} className={"bookingFormButton"} >
                Bruttó újraszámolása.
            </button>
            <button onClick={ignore.bind(this)} style={{marginLeft: '5px'}} className={"bookingFormButton"} >
                Így hagyom.
            </button>
            <div ></div>
        </div>;
    }

    calcBrutto = function (netto, afakulcs) {
        return Math.round(netto + ((netto / 100)) * afakulcs);
    }

    correctionDialog2 = function () {

        var newBrutto = function () {
            var afaSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC2);
            var netto = this.state.data.TETELNE2 * 1;
            this.state.data.TETELBR2 = this.calcBrutto(netto, afaSzazalek);
            this.state.data.TETELAFA2 = this.state.data.TETELBR2 - netto;
            this.setState({diff2Error: false, focused: 'TETELNE2'});
        }

        var ignore = function () {
            this.setState({diff2Error: false});
            this.setTETELBR3({target: {value: this.state.data.GROSVAL - this.state.data.TETELBR1 - this.state.data.TETELBR2}});

        }

        return <div  style={{position: 'absolute', top: '275px', left: '320px', backgroundColor: "#ffffff", zIndex: 2000, boxShadow: '0px 0px 3px #00000077', padding: '3px', display: 'flex'}}>
        
            <div style={{textAlign: 'center', color: '#aa0000'}} >Hibás nettó!</div>
            <button onClick={newBrutto.bind(this)} style={{marginLeft: '5px'}} className={"bookingFormButton"} >
                Bruttó újraszámolása.
            </button>
            <button onClick={ignore.bind(this)} style={{marginLeft: '5px'}} className={"bookingFormButton"} >
                Így hagyom.
            </button>
            <div ></div>
        </div>;
    }

    correctionDialog3 = function () {

        var newBrutto = function () {
            var afaSzazalek = this.resolveAfaValueById(this.state.data.AFAKULC3);
            var netto = this.state.data.TETELNE3 * 1;
            this.state.data.TETELBR3 = this.calcBrutto(netto, afaSzazalek);
            this.state.data.TETELAFA3 = this.state.data.TETELBR3 - netto;
            this.setState({diff3Error: false, focused: 'TETELNE3'});
        }

        var ignore = function () {
            this.setState({diff3Error: false});
        }

        return <div  style={{position: 'absolute', top: '330px', left: '320px', backgroundColor: "#ffffff", zIndex: 2000, boxShadow: '0px 0px 3px #00000077', padding: '3px', display: 'flex'}}>
        
            <div style={{textAlign: 'center', color: '#aa0000'}} >Hibás nettó!</div>
            <button onClick={newBrutto.bind(this)} style={{marginLeft: '5px'}} className={"bookingFormButton"} >
                Bruttó újraszámolása.
            </button>
            <button onClick={ignore.bind(this)} style={{marginLeft: '5px'}} className={"bookingFormButton"} >
                Így hagyom.
            </button>
            <div ></div>
        </div>;
    }

    setMEGJEGYZ = function (e) {
        this.state.data.MEGJEGYZ = e.target.value;
        this.setState({});
    }

    loadPartnerData = function (partnerId) {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/selectPartner.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({partnerId: partnerId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            if (response.data.length > 0) {
                                this.state.partnerName = response.data[0].partnerName;
                                this.state.partnerAdoszam = response.data[0].taxNumber;
                                this.state.partnerIRSZ = response.data[0].zipCode;
                                this.state.partnerCity = response.data[0].city;
                                this.state.partnerUtca = response.data[0].address;
                                this.state.data.SZALADO = response.data[0].taxNumber;

                                this.state.partner["value"] = {id: partnerId, value: this.state.partnerName};
                            }
                            this.setState({});
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    sendingMessage = function () {
        try {
            const url = ajax.getUrlBase() + "soap/sendingMailMessage.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({message: this.state.data.message, DOCUID: this.state.data.DOKUID})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.props.mailSenderDialog("Az üzenetet továbbítottuk.");
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    onPartnerChanged = function () {
        this.loadPartnerData(this.state.partner.value);
    }

    documentStateChanged = function (e) {
        this.state.data.Status = e.target.value;
        this.setState({});
    }

    getBase64 = function (file, fileName, isZip) {
        var reader = new FileReader();
        var self = this;
        reader.readAsDataURL(file);
        reader.onload = function () {

            self.state.lastFilename = '';
            self.state.lastBlobUrl = '';

            self.state.data.pdf = reader.result;
            self.state.data.FILENEVE = fileName;
            if (!isZip) {
                self.setState({});
            } else {
                self.serverSideFileExtractor(self.state.data.pdf, self.state.data.FILENEVE);
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    isZip = function (fileName) {
        if (typeof fileName == "undefined") {
            return false;
        }
        if (fileName == null) {
            return false;
        }
        if (fileName.trim() == "") {
            return false;
        }

        var fileNameAndExtension = fileName.split(".");
        if (fileNameAndExtension.length < 2) {
            return false;
        }

        var extension = fileNameAndExtension[fileNameAndExtension.length - 1];
        extension = extension.trim();
        extension = extension.toLowerCase();

        var isZip = (extension == "zip") ? true : false;

        return isZip;

    }

    uploadPDF = function (e) {
        var files = e.target.files;
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var fileName = file['name'];
            var isZip = this.isZip(fileName);
            this.getBase64(file, fileName, isZip);

        }
    }

    serverSideFileExtractor = function (zip, fileName) {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/serverSideFileExtractor.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({zip: zip, fileName: fileName})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.state.data.pdf = response.data.pdf;
                            this.state.data.FILENEVE = response.data.fileName;
                            this.setState({});
                        }
                    }
                    ).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    setWorkNumberDeatails = function (workNumberDetails) {
        this.state.referenceWorkNumberDetails.current.innerHTML = workNumberDetails;
    }

    companyBackgroundInfoPanelOpen = function () {
        this.props.companyBackgroundInfoPanelOpen(this.state.data.id, this.state.data.SZALADO, this.state.partnerName, false, true);
    }

    b64toBlob = function (dataURI) {
        if (dataURI == null) {
            return null;
        }

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: 'application/pdf'});
    }

    showNav = function (e) {
        this.props.frameState.queryInvoiceDigestDOCUID = this.state.data.DOKUID;
        this.props.showNav(true);
    }

    alertDialogClose = function () {
        this.state.error.active = false;
        this.state.error.msg = "";
        this.setState({});
    }

    alertDialog = function (msg) {
        this.state.error = {};
        this.state.error.active = true;
        this.state.error.msg = msg;
        this.setState({});
    }

    companyBackgroundInfoPanelOpen = function (documentId, taxId, partnerName, isCompany, isPartner) {

        this.setState({
            companyBackgroundInfoPanelDocumentId: documentId,
            companyBackgroundInfoPanelVisible: true,
            companyBackgroundInfoPanelTaxNumber: taxId,
            companyBackgroundInfoPanelTaxPartnerName: partnerName,
            companyBackgroundInfoPanelisCompany: isCompany,
            companyBackgroundInfoPanelisPartner: isPartner
        });
    }
    companyBackgroundInfoPanelClose = function (recordId) {
        console.log(this.state.bookingFormReference);
        if (this.state.bookingFormReference != null) {
            this.state.bookingFormReference.loadRecord(recordId);
        }
        this.setState({companyBackgroundInfoPanelVisible: false});
    }

    getMonthChooser = function () {
        var monthOptions = [];
        for (var i = 0; i < this.months.length; i++) {
            monthOptions.push(<option id={i} key={'monthOption' + i} value={i}>
                {this.months[i]}
            </option>);
        }
        var disabled = (typeof this.state.data.Status != 'undefined' && this.state.data.Status != null && this.state.data.Status > 2) ? true : false;

        return <select 
            disabled={disabled}
            className={"bookingFormButton titleSmall"} 
            style={{minHeight: 20, minWidth: 130, textAlign: 'center'}}
            value={this.state.data.selectedMonth}
            onChange={(e) => {
                            this.state.data.selectedMonth = e.target.value;
                            this.setState({});
                        }}
            id="monthChooser"
            >{monthOptions}</select>;
    }

    render() {
        var alertDialog = "";
        if (this.state.error.active) {
            alertDialog = <AlertDialog 
                blackTheme={this.state.blackTheme}
                alertDialogClose={this.alertDialogClose.bind(this)}>{this.state.error.msg}
            </AlertDialog>
        }


        if (this.state.companyBackgroundInfoPanelVisible) {
            return  <div> <CompanyBackgroundInfoPanel 
                    documentId ={this.state.companyBackgroundInfoPanelDocumentId}
                    partnerName={this.state.companyBackgroundInfoPanelTaxPartnerName}
                    close={this.companyBackgroundInfoPanelClose.bind(this)} 
                    taxNumber={this.state.companyBackgroundInfoPanelTaxNumber}
                    companyBackgroundInfoPanelisCompany={this.state.companyBackgroundInfoPanelisCompany}
                    companyBackgroundInfoPanelisPartner={this.state.companyBackgroundInfoPanelisPartner}
                    instantUpdateForm={this.state.instantUpdateForm.bind(this)}
                    alertDialog={this.alertDialog.bind(this)} 
            
                    />
                {alertDialog}
            </div>
        }


        if (this.state.addPartner == true) {
            return  <PopUpWindow><MasterData 
                    companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)} 
                    blackTheme={this.state.blackTheme} 
                    alertDialog={this.alertDialog.bind(this)} 
                    frameState={this.state}
                    />
                <div style={{display: 'block', textAlign: 'center', marginTop: '10px'}}>
                    <button 
                        className={"bookingFormButton"}  
                        style={{textAlign: 'center'}}
                        onClick={(e) => this.setState({addPartner: false})}
                        >Bezárás</button>
                </div>
            </PopUpWindow>

        }

        var arfolyamStyle = false;
        var arfolyam = this.state.data.exchangeRate;
        if (this.state.data.CURRENC == 1) {
            arfolyamStyle = true;
            arfolyam = '';
        }

        var alert1 = (this.state.diff1Error) ? this.correctionDialog1() : '';
        var alert2 = (this.state.diff2Error) ? this.correctionDialog2() : '';
        var alert3 = (this.state.diff3Error) ? this.correctionDialog3() : '';
        var afaSzazalek1 = this.resolveAfaValueById(this.state.data.AFAKULC1);
        var afaSzazalek2 = this.resolveAfaValueById(this.state.data.AFAKULC2);
        var afaSzazalek3 = this.resolveAfaValueById(this.state.data.AFAKULC3);


        var formInputClass = (this.props.blackTheme) ? "formInputDark" : "formInputLight";
        var comboInputClass = (this.props.blackTheme) ? "comboInputDark" : "comboInputLight";
        var formColorClass = (this.props.blackTheme) ? "#FFFFFFaa" : "000000aa";



        if (this.state.data.FILENEVE != this.state.lastFilename) {
            const blob = this.b64toBlob(this.state.data.pdf);
            var blobUrl = null;

            if (blob != null) {
                blobUrl = URL.createObjectURL(blob);
            }

            this.state.lastFilename = this.state.data.FILENEVE;
            this.state.lastBlobUrl = blobUrl;
        }


        return <div   style={{display: 'contents'}} >
        
            <div style={{minWidth: '600px', position: 'relative'}}>
                {alert1}{alert2}{alert3}
                <BookingFormPaging 
                    loadRecord ={this.loadRecord.bind(this)}
                    selectedRowId={this.props.selectedRowId} 
                    bookingFormTableFilters={this.props.bookingFormTableFilters} 
                    invoiceDirection={this.props.invoiceDirection}
                    bookingFormReference={this}
                    />
                <button onClick={this.updateForm.bind(this)} 
                        className={"bookingFormButton"} 
                        accessKey={"Control+Enter"}
                        style={{position: 'absolute', top: 0, left: 149, width: 59, height: 19, backgroundColor: '#9FD027'}}>Mentés</button>
                <button 
                    onClick={this.closeBookingForm.bind(this)} 
                    className={"bookingFormButton"} 
                    style={{position: 'absolute', top: 0, left: 217, width: 52, height: 19}}>Kilépés</button>
                <button 
                    onClick={(e) => this.setState({addPartner: true})} 
                    className={"bookingFormButton"} 
                    style={{position: 'absolute', top: 0, left: 515, width: 72, height: 19}}>Új partner</button>    
        
                <div className={"titleSmall"} style={{position: 'absolute', top: 24, left: 5, width: 77, height: 22}} >Partner neve:</div>
                <div className={"titleSmall"} style={{position: 'absolute', top: 24, left: 400, width: 81, height: 22}}>Bizonylatszám:</div>
                <FormComboPartner  
                    ref={this.state.referencePartner}
                    style={{position: 'absolute', top: 46, left: 5, minWidth: 368, maxWidth: 368, height: 23, border: 'solid 1px #bbb'}} 
                    formComboRef={this.state.partner}  
                    stateRef={this.state}
                    dataSourceUrl={  "modules/Booking/comboPartners.php"}
                    afterSelect={this.onPartnerChanged.bind(this)}
                    blackTheme={this.props.blackTheme}
                    />
        
                <div style={{position: 'absolute', top: 46, left: 400, minWidth: 185, height: 22, border: 'solid 1px #00000044'}}>    
                    <input 
                        ref={this.state.referenceBizonylatszam}
                        className={formInputClass}
                        onChange={this.setDOKUID.bind(this)}    
                        onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            this.showNav(e)
                                        }
                                    }}
                        value={this.state.data.DOKUID} 
                        style={{position: 'absolute', outline: 'none', top: 1, left: 0, minWidth: 165, height: 18, border: 'none'}} 
                        type="text" 
                        />
                    <div onClick={this.showNav.bind(this)} style={{position: 'absolute', left: '165px', width: '15px', height: '22px', cursor: 'pointer'}}>    
                        <img style={{position: 'absolute', maxWidth: '10px', maxHeight: '10px', top: '8px', left: '3px'}}  src={(this.props.blackTheme) ? openWhite : openBlack}/>  
                    </div>    
                </div>    
        
                <div style={{position: 'absolute', top: 76, left: 5, width: 85, height: 18}} className={"titleSmall"} >Kelt</div>
                <div style={{position: 'absolute', top: 76, left: 125, width: 85, height: 18}} className={"titleSmall"} >Teljesítés</div>
                <div style={{position: 'absolute', top: 76, left: 240, width: 133, height: 18}} className={"titleSmall"} >Fizetési mód</div>
                <div style={{position: 'absolute', top: 76, left: 400, width: 133, height: 18}} className={"titleSmall"} >Fizetési határidő</div>
                <div style={{position: 'absolute', top: 96, left: 5, width: 'auto', height: 27}}>
                    <CustomDate 
                        ref={this.state.referenceKelt}
                        dataKey={'DATUM'} 
                        reload={this.reload.bind(this)} 
                        currentValue={this.state.data.DATUM} 
                        writeBack ={this.state.data}
                        blackTheme={this.props.blackTheme}
                        />
                </div>    
                <div style={{position: 'absolute', top: 96, left: 125, width: 'auto', height: 18}}>
                    <CustomDate 
                        ref={this.state.referenceTeljesites} 
                        dataKey={'TELJESIT'} 
                        reload={this.reload.bind(this)} 
                        currentValue={this.state.data.TELJESIT} 
                        writeBack ={this.state.data}
                        blackTheme={false}
                        />
                </div>
                <div style={{position: 'absolute', top: 94, left: 240, width: 134, height: 27}}>
                    <select className={comboInputClass} 
                            ref={this.state.referenceFizetesiMod}
                            type={"text"}
                            value={this.state.data.FIZMOD}
                            onChange={this.setFizmod.bind(this)}
                            >
                        {this.getFizmodokOptions([{id: 1, label: 'Készpénz'}, {id: 2, label: 'Átutalás'}, {id: 3, label: 'Bankkártya'}, {id: 4, label: 'Egyéb'}])}
                    </select>
                </div>
                <div style={{position: 'absolute', top: 96, left: 400, width: 'auto', height: 27}}>
                    <CustomDate 
                        ref={this.state.referenceFizetesiHatarido} 
                        dataKey={'HATIDO'} 
                        reload={this.reload.bind(this)} 
                        currentValue={this.state.data.HATIDO} 
                        writeBack ={this.state.data}
                        blackTheme={this.props.blackTheme}
                        />
                </div>
        
                <div style={{position: 'absolute', top: 125, left: 5, width: 85, height: 18}} className={"titleSmall"} >Áfa dátum</div>
                <div style={{position: 'absolute', top: 125, left: 125, width: 107, height: 18}} className={"titleSmall"} >Pénznem</div>
                <div style={{position: 'absolute', top: 125, left: 240, width: 134, height: 18}} className={"titleSmall"} >Árfolyam</div>
                <div style={{position: 'absolute', top: 125, left: 400, width: 133, height: 18}} className={"titleSmall"} >Bruttó</div>
        
                <div style={{position: 'absolute', top: 144, left: 5, width: 'auto', height: 29}}>
                    <CustomDate 
                        ref={this.state.referenceAfaDatum} 
                        dataKey={'AFATELJ'} 
                        reload={this.reload.bind(this)} 
                        currentValue={this.state.data.AFATELJ} 
                        writeBack ={this.state.data}
                        blackTheme={this.props.blackTheme}
                        />
                </div>
                <div style={{position: 'absolute', top: 142, left: 125, width: 105, height: 25}}>
                    <select className={comboInputClass} 
                            ref={this.state.referencePenznem}
                            type={"text"}
                            value={this.state.data.CURRENC}
                            onChange={this.setCURRENC.bind(this)}
                            >
                        {this.getPenznemekOptions([{id: 1, label: 'HUF'}, {id: 2, label: 'EUR'}, {id: 3, label: 'USD'}])}
                    </select>
                </div>
                <div style={{position: 'absolute', top: 142, left: 240, width: 132, height: 23}}>
                    <input 
                        readOnly={arfolyamStyle}
                        ref={this.state.referenceArfolyam}
                        className={formInputClass} 
                        type={"text"}
                        value={arfolyam}
                        onChange={this.setExchangeRate.bind(this)}
                        />
                </div>  
                <div style={{position: 'absolute', top: 142, left: 400, width: 129, height: 25}}>
                    <input 
                        onMouseUp={() => {
                                        this.state.referenceBrutto.current.select();
                                    }}
                        ref={this.state.referenceBrutto}
                        onBlur={this.focusLostGROSVAL.bind(this)}
                        onChange={this.setGROSVAL.bind(this)}
                        value={this.state.data.GROSVAL} 
                        className={formInputClass} 
                        type={"text"}/>
                </div>    
        
                <div style={{position: 'absolute', top: 179, left: 6, width: 204, height: 18}}>Könyvelendő értékek:</div>
        
                <div style={{position: 'absolute', top: 206, left: 5, width: 131, height: 18}} className={"titleSmall"} >{afaSzazalek1}% Bruttó:</div>
                <div style={{position: 'absolute', top: 206, left: 180, width: 130, height: 18}} className={"titleSmall"} >{afaSzazalek1}% Áfa alap:</div>
                <div style={{position: 'absolute', top: 206, left: 355, width: 131, height: 18}} className={"titleSmall"} >{afaSzazalek1}% Áfa:</div>
        
                <div>
                    <input style={{position: 'absolute', top: 228, left: 5, width: 130, height: 25}}    
                           onMouseUp={() => {
                                           this.state.referenceBrutto1.current.select();
                                       }}
                           ref={this.state.referenceBrutto1} 
                           onChange={this.setTETELBR1.bind(this)} 
                           onBlur={this.focusLostTETELBR1.bind(this)}
                           value={this.state.data.TETELBR1} 
                           className={formInputClass} 
                           type={"text"} 
                           />
                </div>
                <div>
                    <input style={{position: 'absolute', top: 228, left: 180, width: 130, height: 24}}  
                           ref={this.state.referenceNetto1}
                           onChange={this.setTETELNE1.bind(this)}  
                           value={this.state.data.TETELNE1} 
                           className={formInputClass} 
                           type={"text"}
                           onBlur={this.checkTETELNE1.bind(this)}
                           />
                </div>
                <div>
                    <input 
                        onFocus={this.passFocusTetelAfa1.bind(this)}
                        style={{position: 'absolute', top: 228, left: 355, width: 130, height: 26}}  
                        onChange={() => {
                                    }}
                        value={this.state.data.TETELAFA1} 
                        className={formInputClass} 
                        type={"text"}/>
                </div>
        
                <div style={{position: 'absolute', top: 258, left: 5, width: 130, height: 20}}  className={"titleSmall"} >{afaSzazalek2}% Bruttó:</div>
                <div style={{position: 'absolute', top: 258, left: 180, width: 130, height: 20}}  className={"titleSmall"} >{afaSzazalek2}% Áfa alap:</div>
                <div style={{position: 'absolute', top: 258, left: 355, width: 130, height: 20}}  className={"titleSmall"} >{afaSzazalek2}% Áfa:</div>
        
                <div style={{position: 'absolute', top: 281, left: 5, width: 132, height: 25}} >
                    <input 
                        onMouseUp={() => {
                                        this.state.referenceBrutto2.current.select();
                                    }}
                        onChange={this.setTETELBR2.bind(this)}  
                        onBlur={this.focusLostTETELBR2.bind(this)}
                        value={this.state.data.TETELBR2} 
                        className={formInputClass} 
                        type={"text"} 
                        ref={this.state.referenceBrutto2} />
                </div>
                <div style={{position: 'absolute', top: 281, left: 180, width: 130, height: 24}} >
                    <input 
                        ref={this.state.referenceNetto2}
                        onChange={this.setTETELNE2.bind(this)}  
                        value={this.state.data.TETELNE2} 
                        className={formInputClass} type={"text"}
                        onBlur={this.checkTETELNE2.bind(this)}
                        />
                </div>
                <div style={{position: 'absolute', top: 281, left: 355, width: 130, height: 24}} >
                    <input 
                        onFocus={this.passFocusTetelAfa2.bind(this)}
                        onChange={() => {
                                    }}
                        value={this.state.data.TETELAFA2} 
                        className={formInputClass} 
                        type={"text"}/>
                </div>
        
                <div style={{position: 'absolute', top: 311, left: 5, width: 130, height: 18}} className={"titleSmall"} >{afaSzazalek3}% Bruttó:</div>
                <div style={{position: 'absolute', top: 311, left: 180, width: 130, height: 20}} className={"titleSmall"} >{afaSzazalek3}% Áfa alap:</div>
                <div style={{position: 'absolute', top: 311, left: 355, width: 130, height: 19}} className={"titleSmall"} >{afaSzazalek3}% Áfa:</div>
        
                <div style={{position: 'absolute', top: 332, left: 5, width: 130, height: 22}}>
                    <input 
                        onMouseUp={() => {
                                        this.state.referenceBrutto3.current.select();
                                    }}
                        ref={this.state.referenceBrutto3} 
                        onChange={this.setTETELBR3.bind(this)} 
                        onBlur={this.focusLostTETELBR3.bind(this)}
                        value={this.state.data.TETELBR3} 
                        className={formInputClass} 
                        type={"text"} 
                        />
                </div>
                <div style={{position: 'absolute', top: 332, left: 180, width: 130, height: 24}}>
                    <input 
                        ref={this.state.referenceNetto3}
                        onChange={this.setTETELNE3.bind(this)} 
                        value={this.state.data.TETELNE3} 
                        className={formInputClass} 
                        type={"text"}
                        onBlur={this.checkTETELNE3.bind(this)}
                        />
                </div>
                <div style={{position: 'absolute', top: 332, left: 355, width: 130, height: 23}}>
                    <input 
                        onFocus={this.passFocusTetelAfa3.bind(this)}
                        onChange={() => {
                                    }}
                        value={this.state.data.TETELAFA3} 
                        className={formInputClass} 
                        type={"text"}/>
                </div>
        
                <div style={{position: 'absolute', top: 363, left: 5, width: 130, height: 19}} className={"titleSmall"} >Áfa mentes:</div>
                <div style={{position: 'absolute', top: 363, left: 180, width: 168, height: 19}} className={"titleSmall"}  >Megjegyzés</div>
                <div style={{position: 'absolute', top: 384, left: 5, width: 130, height: 24}}>
                    <input type={"text"} ref={this.state.referenceAfaMentes} className={formInputClass} value={this.state.data.AFAMENT}  onChange={this.setAFAMENT.bind(this)} />
        
                </div>    
        
                <div style={{position: 'absolute', top: 384, left: 179, width: 351, height: 24}}>
        
                    <FormComboMegjegyzes
                        style={{minWidth: '340px', maxWidth: '300px'}}
                        formComboRef={this.state.data}  
                        dataSourceUrl={'modules/Booking/comboMEGJEGYZ.php'}
                        afterSelect ={() => {

                                    }}
                        />      
        
        
                </div>
        
                <div style={{cursor: 'pointer', display: 'flex', position: 'absolute', top: 420, left: 5, width: 505, height: 25, borderBottom: 'solid 1px #dddddd', borderTop: 'solid 1px #dddddd'}}>Partner adatai:
                    <div 
                        id={this.state.data.SZALADO}
                        onClick={this.companyBackgroundInfoPanelOpen.bind(this)} 
                        style={{marginTop: 3, marginLeft: 5}}
                        >
                        <img src={InfoIco} style={{maxHeight: 17}}/>
                    </div>
                </div>
                <div style={{position: 'absolute', top: 445, left: 5, width: 100, height: 15}} className={"titleSmall"}>Partner:</div>
                <div style={{position: 'absolute', top: 460, left: 5, width: 100, height: 15}} className={"titleSmall"}>Adószám:</div>
                <div style={{position: 'absolute', top: 475, left: 5, width: 100, height: 20, borderBottom: 'solid 1px #dddddd'}} className={"titleSmall"}>Cím:</div>
        
        
                <div style={{position: 'absolute', top: 445, left: 87, width: 416, height: 15, color: formColorClass, fontWeight: 'bold'}} className={"titleSmall"}>{this.state.partnerName}</div>
                <div style={{position: 'absolute', top: 460, left: 87, width: 425, height: 15, color: formColorClass, fontWeight: 'bold'}} className={"titleSmall"}>{this.state.partnerAdoszam }</div>
                <div style={{position: 'absolute', top: 475, left: 87, width: 424, height: 20, borderBottom: 'solid 1px #dddddd', color: formColorClass, fontWeight: 'bold'}} className={"titleSmall"}>{this.state.partnerIRSZ + ' ' + this.state.partnerCity + ' ' + this.state.partnerUtca }</div>
        
        
                <div style={{position: 'absolute', top: 505, left: 5, width: 81, height: 30}} className={"titleSmall"}>Fájl neve:</div>
                <div style={{position: 'absolute', top: 500, left: 87, width: 441, height: 30}} >
                    <input 
        
                        ref={this.state.referenceFajlHelye}
                        onChange={this.uploadPDF.bind(this)} 
                        type="file"
        
                        style={{maxWidth: '0px', maxHeight: '0px'}} 
                        />
                    <div className={"titleSmall"} style={{position: 'absolute', top: 0, left: 0, width: 441, height: 40}}>
                        <button 
                            style={{minHeight: '20px', marginRight: '10px', marginTop: '5px'}}    
                            className={"bookingFormButton titleSmall"} 
                            onClick={() => {
                                            this.state.referenceFajlHelye.current.click();
                                        }}>Fájl kiválasztása</button>
        
                        {this.state.data.FILENEVE}
                    </div>
        
                </div>
                <div style={{position: 'absolute', top: 540, left: 5, width: 80, height: 16}} className={"titleSmall"}>Státusz:</div>
                <div style={{position: 'absolute', top: 560, left: 5, width: 170, height: 25, border: 'solid 1px #00000022'}}>
        
                    <select 
                        ref={this.state.referenceStatusz} 
                        value={this.state.data.Status} 
                        onChange={this.documentStateChanged.bind(this)} 
                        className={comboInputClass}
                        style={{minWidth: '170px', border: 'solid 1px #00000000'}}
                        >
                        <option value="1">Iktatva</option>
                        <option value="2">Ellenőrizve</option>
                        <option value="3">Átadva</option>
                        <option value="4">Könyvelve</option>
                        <option value="5">NAV 3.0 nem található</option>
                        <option value="6">Függő</option>
                        <option value="7">Nem könyvelhető</option>
                    </select>
                </div>
        
                <div style={{position: 'absolute', top: 540, left: 205, width: 80, height: 16}} className={"titleSmall"}>Munkaszám:</div>
                <div style={{position: 'absolute', top: 560, left: 205, width: 220, height: 25, marginBottom: '2px', border: 'solid 1px #00000022'}}>
                    <FormComboWorkNumber
                        ref={this.state.workNumberReference}
                        alertDialog={this.props.alertDialog}
                        formComboRef={this.state.workNumber} 
                        stateRef={this.state}
                        dataSourceUrl={ "modules/Booking/comboWorkNumber.php" }
                        setWorkNumberDeatails={this.setWorkNumberDeatails.bind(this)}
                        afterSelect ={() => {
                                    }}
                        />
        
                </div>
        
        
                <div className={"titleSmall"} style={{position: 'absolute', top: 564, left: 430, width: 80, height: 17}} >Fordított áfa:
                    <input 
                        ref={this.state.referenceForditottAfa}
                        onChange={this.setFORDAFA.bind(this)} 
                        checked={(this.state.data.FORDAFA == 1) ? true : false} 
                        type="checkbox"
                        /></div>
        
                <div style={{display: "flex", position: 'absolute', top: 605, left: 5, width: 80, height: 16}} className={"titleSmall"}>
                    <div  style={{position: 'relative', top: "2px", left: "0px"}} >Üzenet:</div> 
                    <img 
                        style={{
                                        position: 'relative',
                                        minWidth: '25px',
                                        minHeight: '25px',
                                        maxWidth: '25px',
                                        maxHeight: '25px',
                                        top: '2px',
                                        left: '10px',
                                        cursor: 'pointer'
                                    }}  
                        src={ attachEmailBlack}
                        title={"Üzenet küldése"}
                        onClick={this.sendingMessage.bind(this)}
                        />  
                </div>
                <div style={{position: 'absolute', top: 602, left: 85, width: 500, height: 17}}>
                    <input 
                        ref={this.state.referenceMessage}
                        className={formInputClass}
                        style={{minWidth: "100%", maxWidth: "100%"}} 
                        type="text"
                        value={this.state.data.message}  
                        onChange={this.setMessage.bind(this)}
                        />
                </div>
                <div style={{position: 'absolute', top: 635, left: 5, width: 80, height: 16}} className={"titleSmall"}>Válasz:</div>
                <div style={{position: 'absolute', top: 640, left: 85, width: 500, height: 17}}>
                    <input 
                        ref={this.state.referenceMessageAnswer}
                        className={formInputClass}
                        style={{minWidth: "100%", maxWidth: "100%"}} 
                        type="text"
                        value={this.state.data.messageAnswer}  
                        onChange={this.setMessageAnswer.bind(this)}
                        />
                </div>
                <div style={{position: 'absolute', top: 675, left: 5, width: 80, height: 16}} className={"titleSmall"}>Részletek</div>
                <div ref={this.state.referenceWorkNumberDetails} style={{position: 'absolute', top: 670, left: 85, width: 500, height: 80, whiteSpace: 'normal'}}>
                    {this.state.workNumberDetails}
                </div>
                <div style={{position: 'absolute', top: 695, left: 5, width: 80, height: 16}} className={"titleSmall"}>Iktatószám</div>
                <div  style={{position: 'absolute', top: 690, left: 85, width: 100, height: 80, whiteSpace: 'normal'}}>
                    {(this.state.data.id) ? this.state.data.id_by_type : ''}
                </div>
                <div  style={{position: 'absolute', top: 690, left: 212, width: 200, height: 80, whiteSpace: 'normal'}}>
                    {this.getMonthChooser()}
                </div>
            </div>
            <div style={{width: '100%', margin: '5px', height: '98vh'}}>
                <iframe style={{width: '100%', height: '90%'}} src={ this.state.lastBlobUrl}/>
            </div>
        
        
        </div>
    }
}

export default BookingForm;