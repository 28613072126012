import React, { Component } from 'react';
import * as ajax from './../../ajax.js';

class InfoPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            BejovoIktatott: 0,
            KimenoIktatott: 0,
            BankiIktatott: 0,
            ElkuldottDokumentum: 0,
            KimenoNavXML: 0,
            companyData: {companyName: '', adoszam: ''}
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCompany !== this.props.currentCompany || prevProps.updateNeed !== this.props.updateNeed) {
            this.loadData();
        }

    }

    loadData = function () {
        try {
            const url = ajax.getUrlBase() + "modules/InfoPanel/InfoPanelData.php";

            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.state.companyData.companyName = response.data.companyData.companyName;
                        this.state.companyData.adoszam = response.data.companyData.adoszam;
                        this.setState({
                            BejovoIktatott: response.data.BejovoIktatott,
                            KimenoIktatott: response.data.KimenoIktatott,
                            KimenoNavXML: response.data.KimenoNavXML,
                            BankiIktatott: response.data.BankiIktatott,
                            ElkuldottDokumentum: response.data.ElkuldottDokumentum,
                            EgyebIrat:response.data.EgyebIrat

                        });
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }


    }

    infoBox = function (title, value, color, openMenu) {
        return <div style={{
                        backgroundColor: color,
                        color: '#FFFFFFdd',
                        margin: '15px',
                        padding: '15px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        minWidth: '180px',
                        maxWidth: '180px',
                        boxShadow: '2px 2px 5px #000000aa',
                        userSelect: 'none'
                         }} onClick={openMenu}>
            <div style={{fontWeight: 'bold', fontSize: '16px'}}>{title}</div>
            <div style={{fontWeight: 'bold', fontSize: '30px'}}>{value}</div>
        </div>;
    }
    openBejovoIktatott = function () {
        this.props.setContent('bookingIncoming');

    }
    openKimenoIktatott = function () {
        this.props.setContent('bookingOutgoing');
    }
    openBankiIktatott = function () {
        this.props.setContent('bank');
    }
    openKimenoNavXML = function () {
        this.props.setContent('outgoing');
    }
    openElkuldott = function () {
        this.props.setContent('pages');
    }
    openEgyebIrat = function () {
        this.props.setContent('docOther');
    }

    render() {
        var bejovoIktatott = this.infoBox("Bejövő iktatott", this.state.BejovoIktatott, "#9ec550", this.openBejovoIktatott.bind(this));
        var kimenoIktatott = this.infoBox("Kimenő iktatott", this.state.KimenoIktatott, "#00acee", this.openKimenoIktatott.bind(this));
        var bankiImport = this.infoBox("Banki import", this.state.BankiIktatott, "#ff4e00", this.openBankiIktatott.bind(this));
        var kimenoNavXml = this.infoBox("Kimenő NAV XML", this.state.KimenoNavXML, "#858583", this.openKimenoNavXML.bind(this));
        var elkuldottDokumentum = this.infoBox("Elküldött dokumentum", this.state.ElkuldottDokumentum, "#23b14d", this.openElkuldott.bind(this));
        var egyebIrat = this.infoBox("Egyéb irat", this.state.EgyebIrat, "#b18723", this.openEgyebIrat.bind(this));

        return <div style={{marginLeft: '10px'}}  >
            <div style={{
                            maxWidth: '940px',
                            margin: 'auto',
                            justifyContent: 'center',
                            maxHeight: '40px',
                            minHeight: '40px',
                            textAlign: 'center',

                            backgroundColor: '#374350',
                            color: '#adb3b8'
                             }} >
                <h3 style={{paddingTop: '5px', fontFamily: "'Titillium Web', sans-serif"}}>Infopanel</h3>
            </div>
            <div style={{
                            display: 'flex',
                            maxWidth: '940px',
                            margin: 'auto',
                            justifyContent: 'center',
                            maxHeight: '30px',
                            minHeight: '30px',
                            textAlign: 'center',

                            backgroundColor: '#586778',
                            color: '#ffffffcc'

                             }} >
                <div style={{marginTop: '0px', marginRight: '30px', fontFamily: "'Titillium Web', sans-serif"}}> {'Cég: ' + this.state.companyData.companyName}</div>
                <div style={{marginTop: '0px'}}> {'Adószám: ' + this.state.companyData.adoszam}</div>
            </div>
            <div style={{margin: 'auto', width: '50%'}}>
                <div style={{display: 'flex', fontWeight: 'bold', marginTop: '10%', justifyContent: 'center'}}>
                    <div>
                        <div style={{textAlign: 'center', margin: 10}}>
                            <div style={{backgroundColor: '#868686', color: 'white', minHeight: 40, paddingTop: 10}}>Iktatás</div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div>{bejovoIktatott}</div>
                            <div>{kimenoIktatott}</div>
                        </div>    
                    </div>
                    <div>
                        <div style={{textAlign: 'center', margin: 10}}>
                            <div style={{backgroundColor: '#868686', color: 'white', minHeight: 40, paddingTop: 10}}>Feltöltés</div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div>{kimenoNavXml}</div>
                            <div>{bankiImport}</div>
                        </div>
                    </div>
        
                </div>
                <div style={{display: 'flex', fontWeight: 'bold', marginTop: '5%', justifyContent: 'center'}}>
                    <div>
                        <div style={{textAlign: 'center', margin: 10}}>
                            <div style={{backgroundColor: '#868686', color: 'white', minHeight: 40, paddingTop: 10}}>Dokumentumok</div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div>{elkuldottDokumentum}</div>
                            <div style={{minWidth: 240}}>{egyebIrat}</div>
                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: 'center', margin: 10}}>
                            <div style={{backgroundColor: 'FFFFFFFF', color: '#000000', minHeight: 40, paddingTop: 10}}></div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div style={{minWidth: 240}}></div>
                            <div style={{minWidth: 240}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                ;
    }
}

export default InfoPanel;