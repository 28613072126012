import React, { Component } from 'react';
import PopUpWindow from './PopUpWindow';
import * as ajax from './../ajax';
import CustomDate from './CustomDate';

class BankiImportDialog extends React.Component {

    constructor(props) {

        super(props);
        var currentDate = this.getCurrentDate();
        this.state = {
            downloadButtonColor:'white',
            data: {
                terhelesDatuma: currentDate,
                transferDate:0,
                filetype: 1
            }
        };

        this.counter = 0;
        this.terhelesDatuma = React.createRef();
        this.downloadButtonColor="";
    }

    getCurrentDate = function () {

        var currentDate = new Date();


        var year = currentDate.getFullYear(); // Év (pl. 2024)
        var month = currentDate.getMonth() + 1; // Hónap (0-tól számolva, ezért hozzá kell adni 1-et)
        var day = currentDate.getDate(); // Nap


        month = (month < 10) ? '0' + month : month;
        day = (day < 10) ? '0' + day : day;

        var formattedDate = year + '-' + month + '-' + day;
        return formattedDate;

    }

    isValidDate = function (dateString) {
        // Ellenőrizzük, hogy a paraméter egy valós dátum objektum-e
        if (typeof dateString == 'undefined' || dateString == null || dateString.trim() == '' || dateString.length != 10) {
            return true;
        }

        var date = new Date(dateString);


        const dayOfWeek = date.getDay();

        // Szombat 6 , Vasárnap 0;
        if (dayOfWeek === 6 || dayOfWeek === 0) {
            return false; // Ha szombat vagy vasárnap, akkor false
        } else {
            return true; // Egyébként true
        }
    }

    reload = function (key, value) {
        this.state.data.terhelesDatuma = value;
        this.forceUpdate();
    }

    render() {
        var errorMsg = "";

        var terhelesDatuma = this.state.data.terhelesDatuma;
        if (this.isValidDate(terhelesDatuma) == false) {
            errorMsg = "A terhelés napja csak banki nap lehet (H-P).";
        }
        
        

        return <PopUpWindow 
            style={{maxWidth: '420px', maxHeight: '420px', zIndex: "1200"}}
            blackTheme={this.props.blackTheme}
            > 
            <div style={{textAlign: 'center'}}><h3>Banki import készítése</h3></div>
            <div style={{minHeight: 70, display: 'flex', justifyContent: 'left', alignItems: 'top'}}>
                <div style={{minWidth: 140, textAlign: 'center'}}>
                    <div class={'titleSmall'} style={{top: 10, left: 5, width: 85, height: 27}}>
                        Terhelés dátuma:
                    </div>
        
                    <div style={{top: 10, left: 5, width: 95, minWidth: 95, height: 27}}>
                        <CustomDate 
                            ref={this.terhelesDatuma}
                            dataKey={'terhelesDatuma'} 
                            reload={this.reload.bind(this)} 
                            currentValue={this.state.data.terhelesDatuma} 
                            writeBack ={this.state.data}
                            blackTheme={this.props.blackTheme}
                            />
                    </div>
                </div>
                
                <div>
                    <div class={'titleSmall'} style={{top: 10, left: 250, width: 85, height: 27}}>
                        Értéknap típusa
                    </div>
                    <div style={{top: 10, left: 250, height: 27}}>
                        <select 
                            style={{minHeight: 30, backgroundColor: 'white', border: 'solid #ddddddAA 1px', borderRadius: 6}}
                            value={this.state.data.transferDate} onChange={(e) => {
                                            this.state.data.transferDate = e.target.value;
                                            this.setState({})
                                        }}>
                            <option value={0}>Fizetési határidő</option>
                            <option value={1}>Megadott dátum</option>
                        </select>
                    </div>
                </div>
                </div>
                <div>
                <div style={{minWidth: 300}}>
                    <div class={'titleSmall'} style={{top: 10, left: 250, width: 85, height: 27}}>
                        Fájl formátuma:
                    </div>
        
                    <div style={{top: 10, left: 250, width: '100%', height: 27}}>
                        <select 
                            style={{minWidth:'100%',minHeight: 30, backgroundColor: 'white', border: 'solid #ddddddAA 1px', borderRadius: 6}}
                            value={this.state.data.filetype} onChange={(e) => {
                                            this.state.data.filetype = e.target.value;
                                            this.setState({})
                                        }}>
                            <option value={1}>Napközbeni átutalás tételenként</option>
                            <option value={2}>Napközbeni átutalás csoportosan</option>
                            <option value={0}>Csoportos átutalás</option>
                        </select>
                    </div>
                </div>
        
        
            </div>
            <div style={{maxHeight: 100, minHeight: 50,  marginTop:20,marginBottom:20, display: 'flex', justifyContent: 'center', width: '100%'}}>
                {(this.props.message == '') ? '' :
                            <button 
                                style={{cursor:'pointer',maxHeight: 100, minHeight: 100, backgroundColor: this.state.downloadButtonColor , border: 'solid #ddddddAA 1px', borderRadius: 6}}
                                onMouseOver={()=>{this.setState({downloadButtonColor:'lightGrey'})}}
                                onMouseLeave={()=>{this.setState({downloadButtonColor:'white'})}}
                                onClick={this.props.bankiImportLetoltese.bind(this)} >Import állomány letöltése.
                            </button>
                }
            </div>
        
            <div style={{minWidth: '100%', bottom: 20, textAlign: 'center'}}>
                <font color={'red'}>&nbsp;{errorMsg}</font><br/>
                <font color={'green'}>&nbsp;{this.props.message}</font>
            </div>
            <div style={{
                            width: '100%',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            bottom: '0'

                        }}
                 >
                <button 
                    style={{marginRight: '10px', backgroundColor: "#9fd027"}}
                    className={"bookingFormButton titleSmall"} 
                    onClick={() => this.props.createBankiImport(this.state.data.terhelesDatuma, this.state.data.filetype,this.state.data.transferDate)} >Indítás</button>
                <button 
                    className={"bookingFormButton titleSmall"} 
                    onClick={() => this.props.hideBankiImportDialog()} >Bezárás</button>
            </div>
        </PopUpWindow>
    }
}

export default BankiImportDialog;

